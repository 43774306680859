<template>
  <EHeader :title="title" @height="setHeight" />
  <div class="store-content">
    <div class="goods-content">
      <a-affix :offset-top="topMenusH" style="background-color: #f6f6f6">
        <div
          class="left-menus"
          :style="{
            height: goodsH + 'px',
          }"
        >
          <a
            v-for="(v, k) in topCates"
            :key="k"
            :class="{ menu: true, on: activeKey === k }"
            @click="onChooseCate(k)"
          >
            <div class="munu-num" v-if="hasGoodsNumInSonCate(v.id) > 0">
              {{ hasGoodsNumInSonCate(v.id) }}
            </div>
            <div class="menu-title">{{ v.title }}</div>
          </a>
        </div>
      </a-affix>

      <div class="goods-list">
        <a-spin :spinning="loading2" tip="提交订单，请稍后">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div class="goods" v-for="(v, k) in dishList" :key="k">
                <div class="goods-image">
                  <img :src="v.pic" :alt="v.name" />
                </div>
                <div class="goods-info">
                  <h1 class="goods-title">{{ v.name }}</h1>
                  <div class="goods-unit">单价：¥{{ v.sellingPrice }}</div>
                </div>
                <div class="goods-num">
                  <a class="btn" @click.stop="onMinus(k)">
                    <img src="@/assets/mobile/minus-2.png" alt="" />
                  </a>
                  <div class="num" @click="openKeyboard(k)">
                    {{ v.count }}
                  </div>
                  <a class="btn" @click.stop="onAdd(k)">
                    <img src="@/assets/mobile/add-2.png" alt="" />
                  </a>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </a-spin>
      </div>
    </div>

    <div class="footer">
      <div
        :class="{
          'footer-box': true,
          on: showShopCar,
        }"
      >
        <a-spin :spinning="loading2">
          <div class="left" @click="$refs.eShopCar.open()">
            <img
              src="@/assets/mobile/shop-car-2.png"
              alt="购物车"
              class="shop-car"
            />
            <span>已选{{ countGoods }}件菜品</span>
          </div>
        </a-spin>

        <div class="right">
          <a-spin :spinning="loading2">
            <div class="order-btn" @click="setAccount">下单</div>
          </a-spin>
        </div>
      </div>
    </div>

    <EShopCar ref="eShopCar" @change="getOrders" @clear="clearShopcar" />
    <EKeyboard ref="eKeyboard" @ok="setNumber" />
  </div>
</template>

<script>
import { defineComponent, ref, toRaw, createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Tab, Tabs, List, PullRefresh } from 'vant'
import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import 'vant/es/tab/style'
import 'vant/es/tabs/style'
import { DishClass, ShoppingCartClass } from '@/apis/dish'
import EHeader from '@/components/mobile/Header2'
import EShopCar from '@/components/mobile/ShopCarWaimai'
import EKeyboard from '@/components/mobile/Keyboard'
import { OrderClass } from '@/apis/order'

const dish = new DishClass()
const shopcar = new ShoppingCartClass()
const order = new OrderClass()

export default defineComponent({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    EHeader,
    EShopCar,
    EKeyboard
  },
  setup () {
    const loading = ref(false)
    const loading2 = ref(false)
    const showShopCar = ref(false)
    const showShopCarCover = ref(false)
    const cates = ref([])
    const dishList = ref([])
    const carDishList = ref([])
    const originalCarGoodsList = ref([])
    const topCates = ref([])
    const sonCates = ref([])
    const activeKey = ref(0)
    const activeSonKey = ref(-1)
    const orders = ref([])

    const headerH = ref(0)
    const goodsH = ref(0)
    const topMenusH = ref(0)
    const goodsIndex = ref(0)

    const refreshing = ref(false)
    const finished = ref(false)
    const pagenation = ref({
      total: 0,
      page: 1
    })
    const queryParams = ref({
      Limit: 20
    })

    const stimer = ref(0)
    const peopleCount = ref(0)
    const storeId = ref('')

    const table = ref()
    const title = ref('欢迎光临')

    return {
      loading,
      cates,
      dishList,
      carDishList,
      originalCarGoodsList,
      topCates,
      sonCates,
      activeKey,
      activeSonKey,
      orders,
      options: {
        labelKey: 'title'
      },
      headerH,
      goodsH,
      topMenusH,
      showShopCar,
      showShopCarCover,
      goodsIndex,

      refreshing,
      finished,
      pagenation,
      queryParams,
      stimer,
      table,
      title,
      peopleCount,
      storeId,
      loading2
    }
  },

  created () {
    this.getTableInfo()
  },

  computed: {
    countGoods () {
      const order = this.orders
      let count = 0

      order.map((e) => {
        count += e.count
      })

      return count.toFixed(1)
    }
  },

  methods: {
    async getTableInfo () {
      const storeId = await this.$store.dispatch('GetScanStoreId')
      if (!storeId) {
        this.$message.error('请扫码打开！')
        return
      }

      this.storeId = storeId
      this.getDishsType()
      this.getOrderDatas()

      this.title = '外卖点餐'
    },
    async getOrderDatas () {
      this.orders = await this.$store.dispatch('GetShopCarGoods2')
    },
    setAccount () {
      this.loading2 = true
      const _this = this
      const data = toRaw(_this.orders)

      if (data.length === 0) {
        this.$message.error('请先选择菜品')
        return false
      }

      const storeId = this.storeId

      _this.$confirm({
        title: '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '确认下单？'
        ),
        onCancel () {
          _this.loading2 = false
        },
        onOk () {
          order
            .scan2({
              shoppingCarts: data,
              storeId: storeId
            })
            .then((resp) => {
              _this.$message.success('下单成功')
              _this.$store.commit('SET_DATA_SHOP_CART_DISHS', [])
              _this.orders = []
              _this.calculateDishCount()
              shopcar.clear().then()
              _this.$router.push({ name: 'waimai-order', params: { id: resp } })
            })
            .catch((e) => {
              _this.loading2 = false
            })
        }
      })
    },
    setHeight (e) {
      const h = document.body.clientHeight
      this.headerH = e

      console.log(h)
      this.goodsH = h - e
    },
    getDishsType () {
      this.$store.dispatch('GetAllDishTypes', this.storeId).then((resp) => {
        this.cates = resp
        this.topCates = resp.filter((x) => x.parentId === 0)

        this.queryParams.DishTypeId = this.topCates[0].id
        this.getDishs(1)
      })
    },

    getDishs (page) {
      this.loading = true
      if (page === 1) {
        this.dishList = []
      }
      this.pagenation.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      dish.gets(q).then((resp) => {
        this.pushDishs(resp.data)

        this.pagenation.total = resp.total
        this.loading = false
        this.refreshing = false

        document.documentElement.scrollTop = 0

        if (page >= resp.total) {
          this.finished = true
        } else {
          this.finished = false
        }
      })
    },

    onRefresh () {
      this.refreshing = true
      this.getDishs(1)
    },

    onLoad () {
      if (
        this.pagenation.page * this.queryParams.Limit >=
          this.pagenation.total ||
        this.pagenation.total <= this.queryParams.Limit
      ) {
        this.finished = true
      } else {
        this.getDishs(++this.pagenation.page)
      }
    },

    pushDishs (data) {
      const orders = this.orders
      data.map((x) => {
        const order = orders.filter((e) => e.dishId === x.id)

        if (order.length > 0) {
          x.count = order[0].count
        } else {
          x.count = 0
        }
      })
      this.dishList.push(...data)
    },

    calculateDishCount () {
      const orders = this.orders
      this.dishList.map((x) => {
        const order = orders.filter((e) => e.dishId === x.id)

        if (order.length > 0) {
          x.count = order[0].count
        } else {
          x.count = 0
        }
      })
    },

    changeOrder (index, e) {
      const dish = this.dishList[index]

      if (e > 0) {
        if (this.orders.filter((x) => x.dishId === dish.id).length > 0) {
          this.orders.map((x) => {
            if (x.dishId === dish.id) {
              x.count = e
            }
          })
        } else {
          this.orders.push({
            dishId: dish.id,
            dishTypeId: dish.dishTypeId,
            count: e
          })
        }
      } else {
        this.orders.map((v, k) => {
          if (v.dishId === dish.id) {
            this.orders.splice(k, 1)
          }
        })
      }

      this.$store.commit('SET_DATA_SHOP_CART_DISHS', toRaw(this.orders))
      this.setShopCar()
    },

    setShopCar () {
      clearInterval(this.stimer)
      this.stimer = setTimeout(() => {
        shopcar.create(toRaw(this.orders)).then()
      }, 1000)
    },

    getOrders (e) {
      this.orders = e
      this.$store.commit('SET_DATA_SHOP_CART_DISHS', e)
      this.setShopCar()

      this.calculateDishCount()
    },

    clearShopcar () {
      this.orders = []
      this.$store.commit('SET_DATA_SHOP_CART_DISHS', [])
      this.calculateDishCount()
    },

    onMinus (index) {
      if (this.dishList[index].count > 0) {
        this.dishList[index].count -= 1

        this.changeOrder(index, this.dishList[index].count)
      }
    },
    onAdd (index) {
      this.dishList[index].count += 1

      this.changeOrder(index, this.dishList[index].count)
    },

    onChooseCate (index) {
      const cate = this.topCates[index]
      this.activeKey = index

      this.queryParams.DishTypeId = cate.id
      this.getDishs(1)
    },

    hasGoodsNumInCate (id) {
      const sons = this.cates.filter((x) => x.parentId === id)
      const sonIds = [id]
      sons.map((e) => {
        sonIds.push(e.id)
      })

      const has = this.orders.filter((x) => sonIds.includes(x.goodsCategoryId))

      let count = 0

      has.map((e) => {
        count += e.count
      })

      return count > 0 ? count.toFixed(1) : ''
    },

    hasGoodsNumInSonCate (id) {
      const has = this.orders.filter((x) => x.goodsCategoryId === id)

      let count = 0

      has.map((e) => {
        count += e.count
      })

      return count > 0 ? count.toFixed(1) : ''
    },

    changeFixed (e) {
      const h = document.body.clientHeight
      const th = this.$refs.topMenus.offsetHeight
      if (e) {
        this.goodsH = h - th
      } else {
        this.goodsH = h - th - this.headerH
      }
    },

    openKeyboard (index) {
      this.goodsIndex = index
      this.$refs.eKeyboard.open(this.dishList[this.goodsIndex].count)
    },

    setNumber (e) {
      this.dishList[this.goodsIndex].count = e
      this.changeOrder(this.goodsIndex, e)
    }
  }
})
</script>

<style lang="less" scoped>
.store-content {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-menus {
    padding: 0.25rem 0;
    background: #fff;
  }
  .goods-content {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    .left-menus {
      width: 1.78rem;
      height: 100%;
      background: #f6f6f6;
      padding-bottom: 1rem;
      overflow-x: hidden;
      overflow-y: auto;
      .menu {
        display: block;
        height: 1rem;
        line-height: 1rem;
        border-left: 0.08rem solid transparent;
        text-align: center;
        color: #333;
        font-size: 0.3rem;
        position: relative;

        .munu-num {
          position: absolute;
          top: 0.1rem;
          right: 0.1rem;
          background: #7236f9;
          font-size: 0.12rem;
          color: #fff;
          border-radius: 0.16rem;
          height: 0.32rem;
          min-width: 0.32rem;
          line-height: 0.3rem;
          padding: 0.03rem 0.1rem;
        }
        &.on {
          border-color: #7236f9;
          color: #7236f9;
        }
      }
    }

    .goods-list {
      width: 5.3rem;
      padding-right: 0.3rem;
      padding-bottom: 1.5rem;
    }
  }
}

.goods {
  display: flex;
  position: relative;
  margin-top: 0.4rem;
  .goods-image {
    width: 1.4rem;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.08rem;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
    }
  }

  .goods-info {
    padding-left: 0.1rem;
    .goods-title {
      font-size: 0.3rem;
      color: #333333;
      line-height: 0.55rem;
      height: 1.1rem;
      padding: 0;
      margin: 0;
    }
    .goods-unit {
      font-size: 0.24rem;
      color: #999;
      line-height: 0.3rem;
    }
  }

  .goods-num {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    .btn {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      box-shadow: 0 0 0.1rem rgba(237, 125, 75, 0.5);
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .num {
      padding: 0 0.1rem;
      min-width: 0.5rem;
      text-align: center;
    }
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 998;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.07rem;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.04);
  background: #fff;
  z-index: 999;

  .footer-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.3rem;
    position: relative;
    z-index: 1;
    background: #fff;

    &.on {
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.04);
    }

    .left {
      display: flex;
      align-items: center;
      font-size: 0.3rem;
      color: #333;

      .shop-car {
        display: block;
        width: 0.75rem;
        height: 0.75rem;
      }
      span {
        margin-left: 0.1rem;
      }
    }
    .right {
      .order-btn {
        width: 2.4rem;
        height: 0.7rem;
        box-shadow: 0 0 0.1rem rgba(237, 125, 75, 0.5);
        border-radius: 0.35rem;
        background: #7236f9;
        color: #fff;
        font-size: 0.3rem;
        text-align: center;
        line-height: 0.7rem;
      }
    }
  }

  .shop-cars {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 1.1rem;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 0.15rem 0.15rem 0 0;
    box-shadow: 0 0 0.4rem rgba(100, 255, 100, 0.2);
    .clear-car {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 0.54rem;
      font-size: 0.18rem;
      color: #333;
      padding-right: 0.3rem;
      position: absolute;
      top: 0;
      width: 100%;
      height: 0.54rem;
      background: #fff;
      z-index: 1;
      img {
        display: block;
        height: 0.2rem;
        width: 0.2rem;
        margin-right: 0.1rem;
      }
    }

    .car-goods-list {
      padding: 0.3rem;
      max-height: 6rem;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
</style>
